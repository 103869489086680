const columns = [
    {
        dataIndex: 'RoleName',
        key: 'RoleName',
        title: '角色名稱'
    },
    {
        dataIndex: 'action',
        key: 'action',
        title: '更多'
    }
]
export default columns
