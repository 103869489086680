import homeTypes from 'types/home'
import { homeType, type action } from '../types'

class homeAction {
    setAll (v: homeTypes.reducerType['all']): action {
        return {
            type: homeType.SET_HOME_ALL,
            payload: {
                all: v
            }
        }
    }

    setSelector (v: homeTypes.reducerType['selector']): action {
        return {
            type: homeType.SET_HOME_SELECTOR,
            payload: {
                selector: v
            }
        }
    }
}
export default new homeAction()
