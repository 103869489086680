import { type action, individualCaseType } from '../types'

const individualCaseState = {
    all: []
}

export const individualCaseReducer = (state = individualCaseState, action: action): any => {
    const a = Object.values(individualCaseType).filter((item) => item === action.type)
    if (a.length > 0) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}
