const columns = [
    {
        dataIndex: 'BanchName',
        key: 'BanchName',
        title: '部門'
    },
    {
        dataIndex: 'Status',
        key: 'Status',
        title: '狀態'
    }
]
export default columns
