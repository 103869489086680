import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { modalType } from 'static'
import { DropdownProps } from 'shared/Dropdown'
import { type usePermissionTypes } from 'hook/usePermission'
import homeTypes from 'types/home'

export const dropdownList = (
    permission: usePermissionTypes.returnType,
    item: homeTypes.TABLE
): DropdownProps['menu'] => [
    permission.isEditable({}) && {
        icon: <EditOutlined />,
        key: modalType.edit,
        label: '編輯'
    },
    permission.isDeleteable({}) && {
        icon: <EditOutlined />,
        key: modalType.delete,
        label: '刪除'
    }
]
