import { Divider, Form, Input } from 'antd'
import api from 'api/Index'
import React from 'react'
import Btn from 'shared/Button'

const Searchbar = (): JSX.Element => {
    const [form] = Form.useForm()

    return (
        <>
            <Divider />
            <Form
                onFinish={(v) => {
                    void api.home.getSelector(v)
                }}
                id="employeeManage"
                autoComplete="off"
                className='row'
            >

                <Form.Item
                    name="HomeName"
                    label="小家名"
                    className='col-md-6'
                >
                    <Input name='HomeName' />
                </Form.Item>

                <Form.Item className='d-flex justify-content-end'>
                    <Btn.Submit text='搜尋' form={form} />
                </Form.Item>
            </Form>
            <Divider />
        </>
    )
}
export default Searchbar
