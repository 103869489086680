import { type action, homeType } from '../types'

const homeState = {
    all: [],
    selector: []
}

export const homeReducer = (state = homeState, action: action): any => {
    const a = Object.values(homeType).filter((item) => item === action.type)
    if (a.length > 0) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}
