import homeTypes from 'types/home'
import apiAbstract from './apiAbstract'

declare namespace params {
    interface add {
        HomeName: string
    }

    interface deleted {
        HomeId: number
    }

    interface update {
        HomeName: string
        HomeId: number
    }

    interface get {
        HomeName: string
    }

    interface selector {
        HomeName?: string
    }
}

class homeApi extends apiAbstract {
    private readonly route = 'home/'

    async get (v?: params.get): Promise<homeTypes.reducerType['all']> {
        return await this.GET<homeTypes.reducerType['all']>({
            url: this.route,
            data: v
        }).then((res) => {
            this.store.dispatch(this.action.home.setAll(res))
            return res
        })
    }

    async add (v: params.add): Promise<null> {
        return await this.PUT<null>({
            url: this.route,
            data: v,
            checkTitle: this.checkTitle.confirmAdd
        })
    }

    async delete (v: params.deleted): Promise<null> {
        return await this.DELETE<null>({
            url: this.route,
            data: v,
            checkTitle: this.checkTitle.confirmDelete
        })
    }

    async update (v: params.update): Promise<null> {
        return await this.POST<null>({
            url: this.route,
            data: v,
            checkTitle: this.checkTitle.confirmUpdate
        })
    }

    async getSelector (v: params.selector): Promise<homeTypes.reducerType['selector']> {
        return await this.GET<homeTypes.reducerType['selector']>({
            url: this.route + 'selector'
        }).then((res) => {
            this.store.dispatch(this.action.home.setSelector(res))
            return res
        })
    }
}
export default new homeApi()
export {
    type params as homeApiParams
}
