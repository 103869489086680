const columns = [
    {
        dataIndex: 'Name',
        key: 'Name',
        title: '姓名'
    },
    {
        dataIndex: 'HomeName',
        key: 'HomeName',
        title: '歸屬小家'
    },
    {
        dataIndex: 'Birthday',
        key: 'Birthday',
        title: '生日'
    },
    {
        dataIndex: 'Sort',
        key: 'Sort',
        title: '排序'
    },
    {
        dataIndex: 'StopFlag',
        key: 'StopFlag',
        title: '啟停用'
    },
    {
        dataIndex: 'action',
        key: 'action',
        title: '更多'
    }
]
export default columns
