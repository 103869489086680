const columns = [
    {
        dataIndex: 'HomeName',
        key: 'HomeName',
        title: '小家'
    },
    {
        dataIndex: 'action',
        key: 'action',
        title: '更多'
    }
]
export default columns
