const columns = [
    {
        dataIndex: 'UserName',
        key: 'UserName',
        title: '使用者名稱'
    },
    {
        dataIndex: 'Status',
        key: 'Status',
        title: '狀態'
    }
]
export default columns
