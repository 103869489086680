import React from "react"

const Index = (): JSX.Element => {
    return (
        <>
            shift
        </>
    )
}

export default Index
