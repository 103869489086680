const columns = [
    {
        dataIndex: 'RoleName',
        key: 'RoleName',
        title: '角色名稱'
    },
    {
        dataIndex: 'Status',
        key: 'Status',
        title: '狀態'
    }
]
export default columns
