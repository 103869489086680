import individualCaseTypes from 'types/individualCase'
import { type action, individualCaseType } from '../types'

class individualCaseAction {
    setAll (v: individualCaseTypes.reducerType['all']): action {
        return {
            type: individualCaseType.SET_INDIVIDUALCASE_ALL,
            payload: {
                all: v
            }
        }
    }
}
export default new individualCaseAction()
