import { Form, FormInstance, Input, InputNumber } from 'antd'
import React from 'react'
import Btn from 'shared/Button'
import { Modal } from 'shared/Modal/Index'
import modal from 'shared/Modal/types'
import { modalTitle, modalType } from 'static'
import homeTypes from 'types/home'
import UserSelector from 'shared/UserSelector/Index'

interface modalInfo {
    type?: modalType
    onSave: (v: FormInstance<any>) => void
    data?: homeTypes.TABLE
}

interface props {
    modalInfo: modal.modalInfoProps<modalInfo>
}

const ModalEdit = ({ modalInfo }: props): JSX.Element => {
    // const { type } = modalInfo
    const [form] = Form.useForm()

    const childMinder = modalInfo?.data?.ChildMinder?.split(',')?.map((item) => parseInt(item)) ?? []
    const socialWorker = modalInfo?.data?.SocialWorker?.split(',')?.map((item) => parseInt(item)) ?? []
    return (
        <>
            <Form
                name="validateOnly"
                autoComplete="off"
                className='row'
                form={form}
                onFinish={() => {
                    modalInfo.onSave(form)
                }}
            >
                <Form.Item
                    name="HomeName"
                    className='col-md-6'
                    label="小家名稱"
                    rules={[{ required: true }]}
                    initialValue={modalInfo?.data?.HomeName || ''}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    className='col-md-6'
                    label="主責保育員"
                    name="ChildMinder"
                    rules={[{ required: true }]}
                >
                    <UserSelector
                        onChange={(v) => {
                            form.setFieldValue('ChildMinder', v?.map((item) => item?.UserId)?.join(','))
                        }}
                        type='checkbox'
                        subComponents='tag'
                        defaultValue={childMinder}
                    />
                </Form.Item>

                <Form.Item
                    className='col-md-6'
                    label="主責社工"
                    name="SocialWorker"
                    rules={[{ required: true }]}
                >
                    <UserSelector
                        onChange={(v) => {
                            form.setFieldValue('SocialWorker', v?.map((item) => item?.UserId)?.join(','))
                        }}
                        type='checkbox'
                        subComponents='tag'
                        defaultValue={socialWorker}
                    />
                </Form.Item>

                <Form.Item
                    name="Sort"
                    className='col-md-6'
                    label="排序"
                    initialValue={modalInfo?.data?.Sort || 0}
                >
                    <InputNumber />
                </Form.Item>
                <Modal.Footer>
                    {
                        () => (
                            <>
                                <Btn.Cancel onClick={() => { void modalInfo.onClose() }} />
                                <Btn.Save />
                            </>
                        )
                    }
                </Modal.Footer>
            </Form>

        </>
    )
}
export default Modal<modalInfo, any>({
    children: ModalEdit,
    title: (v) => `${modalTitle[v?.type]}小家`,
    width: (isLess) => isLess('md') ? '100vw' : '500px'
})
