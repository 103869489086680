const columns = [
    {
        dataIndex: 'BanchName',
        key: 'BanchName',
        title: '部門'
    },
    // {
    //     dataIndex: 'count',
    //     key: 'count',
    //     title: '人數'
    // },
    {
        dataIndex: 'action',
        key: 'action',
        title: '更多'
    }
]
export default columns
