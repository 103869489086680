import api from 'api/Index'
import ModalEdit from '../components/modalEdit/Index'
import { modalType } from 'static'
import individualCaseTypes from 'types/individualCase'

const BtnEvent = ({ type, value }: BtnEventParams<individualCaseTypes.TABLE>): void => {
    const onClose = (): void => {
        ModalEdit.close({})
        void api.individualCase.get()
    }
    if (type === modalType.delete) {
        void api.individualCase.delete({
            IndividualCaseId: value?.IndividualCaseId
        })
            .then(onClose)
        return
    }
    ModalEdit.open({
        type,
        data: value,
        onSave: (form) => {
            const fields = form.getFieldsValue()
            if (type === modalType.edit) {
                void api.individualCase.update({
                    ...fields,
                    IndividualCaseId: value?.IndividualCaseId
                })
                    .then(onClose)
            } else if (type === modalType.add) {
                void api.individualCase.add(fields)
                    .then(onClose)
            }
        }
    })
}
export default BtnEvent
