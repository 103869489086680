import { DatePicker, Form, FormInstance, Input, InputNumber, Select } from 'antd'
import React from 'react'
import Btn from 'shared/Button'
import { Modal } from 'shared/Modal/Index'
import modal from 'shared/Modal/types'
import { modalTitle, modalType } from 'static'
import individualCaseTypes from 'types/individualCase'
import HomeSelector from 'shared/HomeSelector/Index'
import dayjs from 'dayjs'
import Switch from 'shared/AntdOverwrite/Switch'

interface modalInfo {
    type?: modalType
    onSave: (v: FormInstance<any>) => void
    data?: individualCaseTypes.TABLE
}

interface props {
    modalInfo: modal.modalInfoProps<modalInfo>
}

const ModalEdit = ({ modalInfo }: props): JSX.Element => {
    const [form] = Form.useForm()

    return (
        <>
            <Form
                name="validateOnly"
                autoComplete="off"
                className='row'
                form={form}
                onFinish={() => {
                    modalInfo.onSave(form)
                }}
            >
                <Form.Item
                    name="Name"
                    className='col-md-6'
                    label="姓名"
                    initialValue={modalInfo?.data?.Name}
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="Birthday"
                    className='col-md-6'
                    label="生日"
                    initialValue={dayjs(modalInfo?.data?.Birthday)}
                    rules={[{ required: true }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    name="Sexual"
                    className='col-md-6'
                    label="性別"
                    initialValue={modalInfo?.data?.Sexual}
                    rules={[{ required: true }]}
                >
                    <Select
                        options={
                            [
                                { label: '女生', value: 0 },
                                { label: '男生', value: 1 }
                            ]
                        }
                    />
                </Form.Item>
                <Form.Item
                    className='col-md-6'
                    label=""
                    name="HomeBelongId"
                    rules={[{ required: true }]}
                >
                    <HomeSelector
                        onChange={(v) => {
                            form.setFieldValue(
                                'HomeBelongId',
                                v[0]?.HomeId
                            )
                        }}
                        type='radio'
                        subComponents='tag'
                        defaultValue={[modalInfo?.data?.HomeBelongId]}
                    />
                </Form.Item>
                <Form.Item
                    name="Sort"
                    className='col-md-6'
                    label="排序"
                    initialValue={modalInfo?.data?.Sort || 0}
                >
                    <InputNumber />
                </Form.Item>
                <Modal.Footer>
                    {
                        () => (
                            <div className='w-100 d-flex justify-content-between'>
                                <div>
                                    <Switch
                                        label="停用"
                                        formInstance={form}
                                        defaultValue={modalInfo?.data?.StopFlag}
                                        fieldName='StopFlag'
                                        antdSwitchProps={{
                                            checkedChildren: '是',
                                            unCheckedChildren: '否'
                                        }}
                                    />
                                </div>
                                <div>
                                    <Btn.Cancel onClick={() => { void modalInfo.onClose() }} />
                                    <Btn.Save />
                                </div>
                            </div>
                        )
                    }
                </Modal.Footer>
            </Form>
        </>
    )
}
export default Modal<modalInfo, any>({
    children: ModalEdit,
    title: (v) => `${modalTitle[v?.type]}個案`,
    width: (isLess) => isLess('md') ? '100vw' : '500px'
})
