const columns = [
    {
        dataIndex: 'HomeName',
        key: 'HomeName',
        title: '小家'
    },
    {
        dataIndex: 'Status',
        key: 'Status',
        title: '狀態'
    }
]
export default columns
